import React, { useContext, useEffect } from "react";
import { io } from "socket.io-client";
import { restaurant } from "../../providers/RestaurantProvider";
import logger from "../../utils/logger";
import { waitlistContext } from "../../providers/WaitListProvider";



const SocketWrapper = ({ children }) => {
	const restaurantContext = useContext(restaurant);
	const { setWaitlist } = useContext(waitlistContext);

	// useEffect(()=>{
	// 	getFCMToken()
	// },[])

	useEffect(() => {
		if (!restaurantContext.restaurant?._id) {
			return;
		}

		const socket = io(
			(
				process.env.NODE_ENV === "development"
				? "http://localhost:3000"
				: "https://shkspr24632.in"
			),
			{
				transportOptions: {
					polling: {
					  extraHeaders: {
						'x-auth-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzZhMmM2NTk0NjQwYzFjMmU1NmIxNjgiLCJyb2xlIjoiVVNFUiIsImlhdCI6MTY4MTM3ODA0Mn0.fjQFGloMEsHyXB2LWJO_Wzm3brSPV4feuAszesfjXYI"
					  },
					},
				  }
			}
		
		);

		socket.on("connect", () => {
			logger("socket connected");
			const roomName = "dinein" + restaurantContext.restaurant?._id;
			console.log(roomName)
			socket.emit('join room', roomName);
			socket.on('room status', (msg) => {
				logger(msg)
			});
		});


		socket.on("disconnect", () => {
			logger("disconnected");
		});

		socket.on(`/item`, (data) => {
			restaurantContext.updateItem(data._id, data);
		});

		socket.on(`/order`, (data) => {
			if (data.user.phone === JSON.parse(localStorage.getItem("user")).phone) {
				if (
					data.table._id !== JSON.parse(sessionStorage.getItem("table"))._id
				){
					sessionStorage.setItem("table", data.table);
				}
			}
		});
		socket.on(`/waitlist`, (data) => {
			if(localStorage.getItem("waitlistID")==data._id){
				setWaitlist({...data})
			}
		});
		socket.on(`/categoryUpdated`, async () => {
			await restaurantContext.refreshRestaurant()
			
		});


		return () => {
			socket.disconnect();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantContext.restaurant?._id]);
	console.log("waitlistID", localStorage.getItem("waitlistID"))
	return <>{children}</>;
};

export { SocketWrapper as default };
