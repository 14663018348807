import React, { useEffect } from 'react'

export default function DownloadExperiment() {
    useEffect(() => {
        const jsonData = localStorage.getItem("experimentData");
        const blob = new Blob([JSON.stringify(JSON.parse((jsonData)))], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.json';
        link.click()
    }, [])
    return (
        <>
        <div>DownloadExperiment</div>
        <button onClick={()=>{
            localStorage.setItem('experimentData', JSON.stringify([]));
        }}>Clear</button>
        </>
    )
}
