import React, { useEffect, useContext } from "react";
import styles from "./SplashScreen.module.css";
import logo from "../../assets/images/shakespeare_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { restaurant } from "../../providers/RestaurantProvider";
import logger from "../../utils/logger";
import { waitlistContext } from "../../providers/WaitListProvider";

const SplashScreen = () => {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const {setWaitlist} = useContext(waitlistContext)

	const { id: restaurantID } = params;

	const restaurantContext = useContext(restaurant);

	const initialise = async () => {
		try {
			const restaurantRes = await axios.get(
				`/restaurants/${restaurantID}/unmorphed`
			);

			restaurantContext.setRestaurant(restaurantRes.data.restaurant);
			restaurantContext.setItems(restaurantRes.data.items);

			if (location.pathname.split("/")[1] === "restaurant") {
				navigate("/login", { replace: true });
				return;
			} else {
				const code = params.code;
				localStorage.setItem("waitlistCode", code);
				try {
					if (localStorage.getItem("waitlistID")) {
						const waitlistRes = await axios.get(
							`restaurants/${restaurantID}/waitlist/${localStorage.getItem(
								"waitlistID"
							)}`
						);
						console.log(waitlistRes)

						if (waitlistRes.data?.status === "waiting") {
							setWaitlist(waitlistRes.data)
							navigate("/waitlist-details", { replace: true });
						} else {
							localStorage.removeItem("waitlistID");
							navigate("/join-waitlist", { replace: true });
						}
					} else {
						navigate("/join-waitlist", { replace: true });
					}
				} catch (err) {
					logger(err);
					localStorage.removeItem("waitlistID");
					navigate("/join-waitlist", { replace: true });
				}
			}
		} catch (error) {
			logger(error);
			navigate("/not-found", { replace: true });
			return;
		}
	};

	useEffect(() => {
		initialise();
	}, []);

	return (
		<div className={styles.body}>
			<img src={logo} alt="Shakespeare Logo" />
		</div>
	);
};

export default SplashScreen;
