import React, { useContext, useEffect, useRef } from "react";
import styles from "./Welcome.module.css";
import { user } from "../../providers/UserProvider";
import { restaurant } from "../../providers/RestaurantProvider";
import ironmanWelcome from "../../assets/gifs/iron_man_welcome.gif";
import { useNavigate } from "react-router-dom";
import shakespeareLogoText from "../../assets/images/shakespeare_logo_text.png";

const Welcome = () => {
	const userContext = useContext(user);
	const restaurantContext = useContext(restaurant);

	const spanRef = useRef(null);
	const navigate = useNavigate();

	const startAnimation = () => {
		const span = spanRef.current;
		span.style.opacity = 1;

		setTimeout(() => {
			span.style.opacity = 0;

			setTimeout(() => {
				span.style.opacity = 1;
				span.innerHTML = `Welcome to <br /> <strong>${restaurantContext.restaurant.name}</strong>`;

				setTimeout(() => {
					navigate("/order", { replace: true });
				}, 2300);
			}, 500);
		}, 1800);
	};

	useEffect(() => {
		if (window.scrollY > 0) window.scrollTo(0, 0);
		localStorage.setItem("showVeg", true)
		localStorage.setItem("showNonVeg", true)
	}, []);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: "100vh",
			}}
		>
			<div className={styles.body}>
				<img src={shakespeareLogoText} alt="Shakespeare Logo" />
				<span
					ref={spanRef}
					style={{
						opacity: 0,
					}}
				>
					Namaste <br />
					<strong>{userContext.user.name}!</strong>
				</span>
				<div className={styles.welcome}>
					<img
						src={ironmanWelcome}
						alt="Iron Man Welcome"
						onLoad={startAnimation}
					/>
				</div>
				{restaurantContext.restaurant.image && (
					<img src={restaurantContext.restaurant.image} alt="Restaurant Logo" />
				)}
				<span
					style={{
						height: "8%",
					}}
				></span>
			</div>
			<footer className={styles.footer}>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
		</div>
	);
};

export default Welcome;
