import React, { useEffect, useState } from 'react'
import styles from './Rating.module.css'
import shakespeareLogoText from "../../assets/images/shakespeare_logo_text.png";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Rating, Select, TextField } from '@mui/material';
import commonStyles from '../../styles.module.css'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon fontSize='1.3rem' color="error" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon fontSize='1.3rem' color="error" />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon fontSize='1.3rem' color="warning" />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon fontSize='1.3rem' color="success" />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon fontSize='1.3rem' color="success" />,
        label: 'Very Satisfied',
    },
};
const customIcons4Star = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon fontSize='1.3rem' color="error" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentSatisfiedIcon fontSize='1.3rem' color="warning" />,
        label: 'Neutral',
    },
    3: {
        icon: <SentimentSatisfiedAltIcon fontSize='1.3rem' color="success" />,
        label: 'Satisfied',
    },
    4: {
        icon: <SentimentVerySatisfiedIcon fontSize='1.3rem' color="success" />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}
function IconContainer4star(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons4Star[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};


export default function Ratings() {
    const { restaurantID, orderID } = useParams();
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState({});
    const [user, setUser] = useState({ id: Date.now().toString(), name: "", privacyAccept: true })
    const [showRatings, setShowRatings] = useState(false)
    const fetchOrderItems = async () => {
        try {
            const res = await axios.get(
                `/restaurants/${restaurantID}/orders/${orderID}/items`
            );
            console.log(res)
            if (res.data.ratingStatus) {
                setItems({});
                if (res.data.reviewUrl) {
                    console.log("Review URL found:", res.data.reviewUrl);
                    window.location.href = res.data.reviewUrl;
                }
            } else {
                let rating = {
                    taste: 0,
                    portion: 0,
                    orderAgain: 0
                }
                for (let item in (res.data ?? {})) {
                    res.data[item] = {
                        ...res.data[item],
                        ...rating
                    }
                }
                setItems(res.data)
            }
            return res;
        } catch (err) {
            console.log(err);
            throw new Error("Error");
        }
    }
    const handleAddRating = async () => {
        try {
            setLoading(true)
            const res = await axios.post(
                `/restaurants/${restaurantID}/rating/order/${orderID}`,
                { items, user }
            );
            window.location.reload()
            return res
        } catch (err) {
            console.log(err);
            throw new Error("Error")
        } finally {
            setLoading(false)
        }

    }
    useEffect(() => {
        toast.promise(fetchOrderItems(), {
            pending: "Loading...",
            success: "refreshed",
            error: "Failed"
        })
    }, [])

    const handleSwitchChange = (e) => {
        const [id, name] = e.target.name.split("-");
        let score = 0;
        if (name == 'taste') {
            score = 2.5
        }
        if (name == "orderAgain") {
            score = 1.25
        }
        if (name == 'portion') {
            score = 1.25
        }


        setItems({
            ...items,
            [id]: {
                ...items[id],
                [name]: e.target.checked ? score : 0,
            }
        })
    }

    const handleNext = () => {
        setShowRatings(true);
    }
    return (
        <div className={styles.main}>
            <div className={styles.head}>
                <img src={shakespeareLogoText} alt="Shakespeare Logo" />
            </div>
            {
                !showRatings ?
                    <div className={styles.ratingContainer} style={{ marginTop: "300px" }}>
                        <TextField onChange={(e) => setUser({ ...user, name: e.target.value })} value={user.name} label="Name" type="text" placeholder='name' />
                        {/* <TextField onChange={(e) => setUser({ ...user, age: e.target.value })} sx={{ marginTop: "10px" }} value={user.age} label="Age (Optional)" type="text" placeholder='age (optional)' /> */}
                        <FormControl fullWidth sx={{marginTop:"10px"}}>
                            <InputLabel id="demo-simple-select-label">Age Group</InputLabel>
                            <Select
                            
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={user.age}
                                label="Age Group"
                                onChange={(e) => setUser({ ...user, age: e.target.value })}
                            >
                                <MenuItem value={"15-20"}>15-20</MenuItem>
                                <MenuItem value={"20-25"}>20-25</MenuItem>
                                <MenuItem value={"25-30"}>25-30</MenuItem>
                                <MenuItem value={"30-35"}>30-35</MenuItem>
                                <MenuItem value={"35-40"}>35-40</MenuItem>
                                <MenuItem value={"40-45"}>40-45</MenuItem>
                                <MenuItem value={"45-50"}>45-50</MenuItem>
                                <MenuItem value={"Above 50"}>Above 50</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel sx={{ marginTop: "20px" }} control={<Checkbox checked={user.privacyAccept} onChange={(e) => setUser({ ...user, privacyAccept: e.target.checked })} />} label={<a sx={{ fontSize: "0.5rem" }} href="https://shakespeare.vision/privacy">Accept privacy and policy</a>} />
                        <button className={commonStyles.primary_button} onClick={handleNext}>Next</button>
                    </div>
                    :
                    <div className={styles.ratingContainer}>
                        {
                            Object.values(items ?? {}).map((item, index) => {
                                return <div className={styles.item} key={index}>
                                    <h4>{item.name}</h4>
                                    <div className={styles.itemsOptions}>
                                        <b>How would you rate the taste?</b>
                                        <StyledRating
                                            size={"large"}
                                            name="highlight-selected-only"
                                            value={item?.taste}
                                            onChange={(event, newValue) => {
                                                setItems({
                                                    ...items,
                                                    [item._id]: {
                                                        ...items[item._id],
                                                        taste: newValue,
                                                    }
                                                })
                                            }}
                                            IconContainerComponent={IconContainer}
                                            getLabelText={(value) => customIcons[value].label}
                                            highlightSelectedOnly
                                        />
                                    </div>
                                    <div className={styles.itemsOptions}>
                                        <b>How Satisfied are you with the portion size?</b>
                                        <StyledRating
                                            size={"large"}
                                            name="highlight-selected-only"
                                            value={(item?.portion)}
                                            onChange={(event, newValue) => {
                                                setItems({
                                                    ...items,
                                                    [item._id]: {
                                                        ...items[item._id],
                                                        portion: newValue,
                                                    }
                                                })
                                            }}
                                            IconContainerComponent={IconContainer4star}
                                            getLabelText={(value) => customIcons4Star[value].label}
                                            max={4}
                                            highlightSelectedOnly
                                        />
                                    </div>
                                    <div className={styles.itemsOptions}>
                                        <b>How Likely are you to order this dish again?</b>
                                        <StyledRating
                                            size={"large"}
                                            name="highlight-selected-only"
                                            value={(item?.orderAgain)}
                                            onChange={(event, newValue) => {
                                                setItems({
                                                    ...items,
                                                    [item._id]: {
                                                        ...items[item._id],
                                                        orderAgain: newValue,
                                                    }
                                                })
                                            }}
                                            IconContainerComponent={IconContainer4star}
                                            getLabelText={(value) => customIcons4Star[value].label}
                                            max={4}
                                            highlightSelectedOnly
                                        />
                                        {/* <Switch checked={item?.presentation ? true : false} name={item._id + "-presentation"} onChange={handleSwitchChange} color="warning" /> */}
                                    </div>
                                    {/* <div className={styles.itemsOptions}>
                                <b>Is the dish consistent?</b>
                                <Switch checked={item?.consistency ? true : false} name={item._id + "-consistency"} onChange={handleSwitchChange} color="warning" />
                            </div> */}
                                </div>
                            })
                        }
                        <div className={styles.itemsOptions}>
                            <TextField fullWidth sx={{ marginTop: "10px" }} onChange={(e) => setUser({ ...user, comment: e.target.value })} value={user.comment} label="Comment" type="text" />
                        </div>
                        <div className={styles.btn}>
                            {
                                JSON.stringify(items) != "{}" &&
                                <button disabled={loading} className={commonStyles.primary_button} onClick={() => {
                                    toast.promise(handleAddRating(), {
                                        pending: "Loading...",
                                        success: "Rating Added",
                                        error: "Rating Failed"
                                    })
                                }}>Submit</button>
                            }
                        </div>
                    </div>
            }

        </div>
    )
}
