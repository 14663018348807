import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./OTPDialog.module.css";
import axios from "axios";
import toast from "../../utils/toast";
import logger from "../../utils/logger";

const OTPDialog = ({ open, handleClose, otpHash, handleSuccess }) => {
	const [otp, setOtp] = useState("");

	const handleSubmit = async () => {
		try {
			const res = await axios.post(
				`/restaurants/${sessionStorage.getItem("restaurant")}/kots/verifyOTP`,
				{
					otpHash,
					otp,
				}
			);

			logger(res.data);

			handleSuccess();
		} catch (error) {
			logger(error);
			toast.error("Invalid OTP", {
				autoClose: 2000,
			});
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Enter OTP</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="otp"
					label="OTP"
					type="number"
					fullWidth
					value={otp}
					onChange={(e) => setOtp(e.target.value.toString())}
				/>
				<GradientBorder
					gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
					width="1px"
					className={styles.submit}
				>
					<button onClick={handleSubmit}>Submit</button>
				</GradientBorder>
			</DialogContent>
		</Dialog>
	);
};

export default OTPDialog;
