import axios from "axios";
import React, { useContext, useState } from "react";
import { restaurant } from "../../providers/RestaurantProvider";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import styles from "./Waitlist.module.css";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import shakespeareLogoText from "../../assets/images/shakespeare_logo_text.png";
import commonStyles from "../../styles.module.css";
import { useNavigate } from "react-router-dom";
import { waitlistContext } from "../../providers/WaitListProvider";
import { getFCMToken } from "../../utils/firebase";

const Waitlist = () => {
	const restaurantContext = useContext(restaurant);
	const {setWaitlist} = useContext(waitlistContext)
	const navigate = useNavigate();

	const [name, setName] = useState(
		JSON.parse(localStorage.getItem("user"))?.name || ""
	);
	const [phone, setPhone] = useState(
		JSON.parse(localStorage.getItem("user"))?.phone || ""
	);
	const [personCount, setPersonCount] = useState(1);

	const handleAdd = async (e) => {
		e.preventDefault();

		if (!name || !phone || !personCount) {
			toast.error("Please fill all the fields");
			return;
		}

		if (!name.match(/^[a-zA-Z ]+$/)) {
			toast.error("Name can only contain alphabets");
			return;
		}

		if (!phone.match(/^[0-9]{10}$/)) {
			toast.error("Invalid phone number");
			return;
		}

		if (!personCount.toString().match(/^[0-9]+$/)) {
			toast.error("Invalid number of people");
			return;
		}

		try {
			let fcmToken=""
			try{
				fcmToken= await getFCMToken()
			}catch(err){
				console.log("error fetching fcm token")
			}
			console.log(fcmToken)
			const res = await axios.post(
				`/restaurants/${sessionStorage.getItem("restaurant")}/waitlist`,
				{
					name,
					phone,
					personCount,
					code: localStorage.getItem("waitlistCode"),
					fcmToken
				}
			);

			logger(res);
			setWaitlist(res.data)
			localStorage.setItem("waitlistID", res.data._id);
			toast.success("Added to waitlist");
			navigate("/waitlist-details");
		} catch (err) {
			logger(err);
			if (err.response?.status === 401) {
				toast.error("Invalid Waitlist Code");
			} else {
				toast.error(err.response?.data?.message ?? "Network connection failed");
			}
		}
	};

	return (
		<div className={styles.body}>
			<form className={styles.form}>
				<img src={shakespeareLogoText} alt="Shakespeare Logo" />
				{restaurantContext.restaurant.image && (
					<img src={restaurantContext.restaurant.image} alt="Restaurant Logo" />
				)}
				<div className={styles.input_wrapper}>
					<label htmlFor="name">Name</label>
					<GradientBorder
						gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
						width="1px"
						className={styles.input}
					>
						<input
							type="text"
							name="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</GradientBorder>
				</div>
				<div className={styles.input_wrapper}>
					<label htmlFor="phone">Phone</label>
					<GradientBorder
						gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
						width="1px"
						className={styles.input}
					>
						<input
							type="number"
							name="phone"
							maxLength={10}
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						/>
					</GradientBorder>
				</div>
				<div className={styles.input_wrapper}>
					<label htmlFor="table">No. of People</label>
					<GradientBorder
						gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
						width="1px"
						className={[styles.input, styles.table_number].join(" ")}
					>
						<input
							name="table"
							value={personCount}
							onChange={(e) => setPersonCount(e.target.value)}
						/>
					</GradientBorder>
				</div>
				<button
					className={commonStyles.primary_button}
					onClick={handleAdd}
					style={{
						fontSize: "16px",
					}}
				>
					Join Waitlist
				</button>
			</form>
			<footer>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
		</div>
	);
};

export default Waitlist;
