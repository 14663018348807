import axios from "axios";
import { createContext, useState } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";

const restaurant = createContext({});
const { Provider } = restaurant;

const RestaurantProvider = ({ children }) => {
	const [restaurant, setStateRestaurant] = useState(
		JSON.parse(sessionStorage.getItem("restaurantData")) || {}
	);
	const [items, setStateItems] = useState(
		JSON.parse(sessionStorage.getItem("restaurantItems")) || []
	);
	const [table, setStateTable] = useState(
		JSON.parse(sessionStorage.getItem("table")) || {}
	);

	const setItems = (items) => {
		sessionStorage.setItem("restaurantItems", JSON.stringify(items));
		setStateItems(items);
	};

	const setTable = (table) => {
		sessionStorage.setItem("table", JSON.stringify(table));
		setStateTable(table);
	};

	const updateItem = (itemID, item) => {
		const newItems = { ...items };
		newItems[itemID] = item;
		setItems(newItems);
	};

	const setRestaurant = (restaurant) => {
		sessionStorage.setItem("restaurant", restaurant._id);
		sessionStorage.setItem("restaurantData", JSON.stringify(restaurant));
		setStateRestaurant(restaurant);
	};

	const refreshRestaurant = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${sessionStorage.getItem("restaurant")}/unmorphed`
			);

			setRestaurant(res.data.restaurant);
			setItems(res.data.items)
			toast.success("Restaurant refreshed", {
				autoClose: 2000,
			});
			return res
		} catch (err) {
			logger(err);
			toast.error("Error refreshing restaurant", {
				autoClose: 2000,
			});
		}
	};

	const clearRestaurant = () => {
		setStateRestaurant({});
	};

	return (
		<Provider
			value={{
				restaurant,
				setRestaurant,
				refreshRestaurant,
				clearRestaurant,
				setTable,
				table,
				items,
				setItems,
				updateItem,
			}}
		>
			{children}
		</Provider>
	);
};

export { RestaurantProvider, restaurant };
