import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedComponent({Component}) {
    const restaurant= sessionStorage.getItem('restaurant')
    const table= sessionStorage.getItem('table')
  return (
    <>
    {
       ( restaurant && table )? <Component/> : <Navigate to="/"></Navigate>
    }
    </>
  )
}
