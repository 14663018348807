import { MenuBookRounded } from "@mui/icons-material";
import { Fab, Popover, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./MenuFAB.module.css";
import GradientBorder from "../../components/GradientBorder/GradientBorder";

const MenuFAB = ({ menu, onCategoryClick }) => {
	const fabRef = useRef(null);

	const [showMenu, setShowMenu] = useState(false);

	return (
		<>
			<Fab
				translate="no"
				ref={fabRef}
				sx={{
					position: "fixed",
					bottom: "90px",
					right: "20px",
					zIndex: "1000",
					backgroundColor: "#20140A",
					color: "#B29466",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "60px",
					height: "60px",
					"&:hover": {
						backgroundColor: "#20140A",
					},
				}}
				onClick={() => setShowMenu(true)}
			>
				<MenuBookRounded fontSize="medium" />
				<Typography variant="caption" component="div" color="#B29466">
					MENU
				</Typography>
			</Fab>
			<Popover
				open={showMenu}
				anchorEl={fabRef.current}
				onClose={() => setShowMenu(false)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				sx={{
					"& .MuiPopover-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(161.3deg, #756349 31.59%, #D8B785 98.49%)"
					width="4px"
					className={styles.menu_wrapper}
				>
					<div>
						{menu.map((category) => (
							<div
								className={styles.menu_item}
								onClick={() => {
									setShowMenu(false);
									onCategoryClick(category);
								}}
							>
								{category.name}
							</div>
						))}
					</div>
				</GradientBorder>
			</Popover>
		</>
	);
};

export default MenuFAB;
