import React from "react";
import { Search } from "@mui/icons-material";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../styles.module.css";

const Header = ({ onBack, onSearch, query }) => {
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<div className={styles.header}>
				<div
					className={[styles.header_button, commonStyles.primary_button].join(
						" "
					)}
					onClick={onBack}
				>
					Back
				</div>
				<div className={styles.search}>
					<Search htmlColor="white" />
					<input
						type="text"
						placeholder="Search"
						value={query}
						onChange={(e) => onSearch(e.target.value)}
						size={1}
					/>
				</div>
				<div
					className={[styles.header_button, commonStyles.primary_button].join(
						" "
					)}
					onClick={() => {
						navigate("/history");
					}}
				>
					My Orders
				</div>
			</div>
		</React.Fragment>
	);
};

export default Header;
