import { createContext, useState } from "react";

const waitlistContext = createContext({});

const { Provider } = waitlistContext;

const WaitlistProvider = ({ children }) => {

  const [waitlist, setStateWaitlist] = useState(
    JSON.parse(localStorage.getItem("waitlistData")) || {}
  );

  const setWaitlist = (data) => {
    localStorage.setItem("waitlistData", JSON.stringify(data))
    setStateWaitlist({...data})
  };
  console.log(waitlist)

  return (
    <Provider
      value={{
        waitlist,
        setWaitlist,
      }}
    >
      {children}
    </Provider>
  );
};

export { WaitlistProvider, waitlistContext };
