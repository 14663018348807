import React from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./KOTItemCard.module.css";
import { Typography } from "@mui/material";

const KOTItemCard = ({ item, kot }) => {
	return (
		<>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.item_card_wrapper}
			>
				<div>
					<div className={styles.item}>
						<div className={styles.item_details}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
							>
								{item.item.name}
							</Typography>
							<ul className={styles.property_list}>
								{(item.properties || []).map((prop) => {
									return (
										<Typography
											variant="caption"
											component="div"
											color="#824500"
											fontWeight={400}
										>
											<li key={prop.name}>
												{prop.name} : {prop?.option ? prop?.option : prop?.options?.join(", ") ?? ""}
											</li>
										</Typography>
									);
								})}
							</ul>
						</div>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
						>
							&times; {item.quantity}
						</Typography>
					</div>
					{item.note && (
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={400}
							textAlign="left"
						>
							<strong>Note: </strong> {item.note}
						</Typography>
					)}
				</div>
			</GradientBorder>
		</>
	);
};

export default KOTItemCard;
