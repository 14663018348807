import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import { restaurant } from "../../providers/RestaurantProvider";
import { user } from "../../providers/UserProvider";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
import shakespeareLogoText from "../../assets/images/shakespeare_logo_text.png";
import logger from "../../utils/logger";
import axios from "axios";
import commonStyles from "../../styles.module.css";

const Login = () => {
	const restaurantContext = useContext(restaurant);
	const userContext = useContext(user);

	const [name, setName] = useState(
		JSON.parse(localStorage.getItem("user"))?.name || ""
	);
	const [phone, setPhone] = useState(
		JSON.parse(localStorage.getItem("user"))?.phone || ""
	);
	const [table, setTable] = useState("");

	const navigate = useNavigate();

	const handleConfirm = async (e) => {
		e.preventDefault();

		if (!name || !phone || !table) {
			toast.error("Please fill all the fields");
			return;
		}

		if (!phone.match(/^[0-9]{10}$/)) {
			toast.error("Please enter a valid phone number");
			return;
		}

		if (!name.match(/^[a-zA-Z ]+$/)) {
			toast.error("Please enter a valid name");
			return;
		}

		try {
			const res = await axios.get(
				`/restaurants/${restaurantContext.restaurant._id}/tables/name/${table}`
			);

			logger(res);

			if (Object.keys(res.data).length === 0) {
				toast.error("Table Number not Valid");
				return;
			}

			userContext.setUser({
				name,
				phone,
			});

			restaurantContext.setTable(res.data);

			navigate("/welcome", { replace: true });
		} catch (err) {
			logger(err);
			toast.error("Table Number not Valid");
		}
	};

	console.log("app running....")

	return (
		<div className={styles.body}>
			<form className={styles.form}>
				<img src={shakespeareLogoText} alt="Shakespeare Logo" />
				{restaurantContext.restaurant.image && (
					<img src={restaurantContext.restaurant.image} alt="Restaurant Logo" />
				)}
				<div className={styles.input_wrapper}>
					<label htmlFor="name">Name</label>
					<GradientBorder
						gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
						width="1px"
						className={styles.input}
					>
						<input
							type="text"
							name="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</GradientBorder>
				</div>
				<div className={styles.input_wrapper}>
					<label htmlFor="phone">Phone</label>
					<GradientBorder
						gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
						width="1px"
						className={styles.input}
					>
						<input
							type="number"
							name="phone"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						/>
					</GradientBorder>
				</div>
				<div className={styles.input_wrapper}>
					<label htmlFor="table">Table Number</label>
					<GradientBorder
						gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
						width="1px"
						className={[styles.input, styles.table_number].join(" ")}
					>
						<input
							name="table"
							value={table}
							onChange={(e) => setTable(e.target.value)}
						/>
					</GradientBorder>
				</div>
				<button
					className={commonStyles.primary_button}
					onClick={handleConfirm}
					style={{
						fontSize: "16px",
					}}
				>
					Confirm
				</button>
			</form>
			<footer>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
		</div>
	);
};

export default Login;
