import { Badge, Dialog, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./ItemCard.module.css";
import { cart } from "../../providers/CartProvider";
import NoteDialog from "./NoteDialog";
import toast from "../../utils/toast";
import { Liquor } from "@mui/icons-material";
import vegIcon from "../../assets/icons/veg.png";
import nonVegIcon from "../../assets/icons/nonveg.png";

const ItemCard = ({ item, onProperties, theme }) => {
	console.log(item, "recItem")
	const cartContext = useContext(cart);
	const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
	const [complementsDialogOpen, setComplementsDialogOpen] = useState(false);

	const quantity = cartContext.getQuantity(item._id);

	const handleClick = () => {
		if (!item.available) {
			toast.error("Item not available : " + item.unavailableReason, {
				autoClose: 2000,
			});
			return;
		}
		if (item.properties?.length === 0) {
			cartContext.addItem(item);
		} else {
			onProperties();
		}
	};

	const ComplementsDialog = () => {
		return (
			<Dialog
				open={complementsDialogOpen}
				onClose={() => setComplementsDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#9E5E28"
							sx={{
								marginBottom: "12px",
							}}
						>
							Complements for {item.name}:
						</Typography>
						<ul className={styles.complements_list}>
							{item?.complements?.map((complement) => (
								<li key={complement}>
									<Typography variant="body2" component="div" color="#9E5E28">
										{complement}
									</Typography>
								</li>
							))}
						</ul>
					</div>
				</GradientBorder>
			</Dialog>
		);
	};

	return (
		<>
			<Badge
				badgeContent={item?.complements?.length > 0 ? "C" : null}
				color="error"
				overlap="circular"
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{
					"& .MuiBadge-badge": {
						top: "10%",
						right: "8%",
					},
				}}
				onClick={
					item?.complements?.length > 0
						? () => {
								setComplementsDialogOpen(true);
						  }
						: null
				}
			>
				<GradientBorder
					gradient={
						item.available
							? "linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							: "grey"
					}
					width="1px"
					className={
						styles.card_wrapper +
						(item.available ? "" : ` ${styles.unavailable}`)+
						(theme==="brown" ? ` ${styles.theme_brown}`: '')
					}
				>
					<div>
						<div
							className={styles.icon_row}
							style={{
								opacity: item.available ? 1 : 0,
							}}
						>
							{item.isBar ? (
								<Liquor color="inherit" fontSize="small" />
							) : (
								<img src={item.isVeg===false ? nonVegIcon : vegIcon} alt="item" />
							)}
							<Typography variant="body2" component="div" color="#9E5E28">
								{item.price}/-
							</Typography>
						</div>
						<Typography
							variant="body1"
							component="div"
							color={item.available ? "#9E5E28" : "#9B9B9B"}
							onClick={handleClick}
						>
							{item.name}
						</Typography>
						{!item.available ? (
							<Typography
								variant="body2"
								component="div"
								color={item.available ? "#9E5E28" : "#9B9B9B"}
								onClick={handleClick}
								fontWeight={600}
							>
								Unavailable
							</Typography>
						) : item.properties?.length === 0 ? (
							<div className={styles.icon_row}>
								<Typography
									variant="body2"
									component="div"
									color="#9E5E28"
									onClick={() => {
										if (quantity === 0) {
											toast.error("Please add item to cart first", {
												autoClose: 1000,
											});
											return;
										}
										setIsNoteDialogOpen(true);
									}}
								>
									Note
								</Typography>
								<div className={styles.counter}>
									<div
										className={styles.counter_button}
										onClick={
											quantity === 0
												? undefined
												: () => {
														cartContext.subtractItem(item);
												  }
										}
									>
										-
									</div>
									<div className={styles.counter_value}>{quantity}</div>
									<div
										className={styles.counter_button}
										onClick={() => {
											cartContext.addItem(item);
										}}
									>
										+
									</div>
								</div>
							</div>
						) : (
							<Typography
								variant="body2"
								component="div"
								color="#9E5E28"
								onClick={handleClick}
							>
								Customise
							</Typography>
						)}
					</div>
				</GradientBorder>
			</Badge>
			<NoteDialog
				isOpen={isNoteDialogOpen}
				setIsOpen={setIsNoteDialogOpen}
				item={item}
			/>
			<ComplementsDialog />
		</>
	);
};

export default ItemCard;
