import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import axios from "axios";
import Login from "./pages/Login/Login";
import OrderScreen from "./pages/OrderScreen/OrderScreen";
import NotFound from "./pages/NotFound/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import { RestaurantProvider } from "./providers/RestaurantProvider";
import { UserProvider } from "./providers/UserProvider";
import Welcome from "./pages/Welcome/Welcome";
import { CartProvider } from "./providers/CartProvider";
import Cart from "./pages/Cart/Cart";
import SocketWrapper from "./components/SocketWrapper/SocketWrapper";
import History from "./pages/History/History";
import Waitlist from "./pages/Waitlist/Waitlist";
import WaitlistDetails from "./pages/WaitlistDetails/WaitlistDetails";
import { WaitlistProvider } from "./providers/WaitListProvider";
import ProtectedComponent from "./components/ProtectedComponent/ProtectedComponent";
import Ratings from "./pages/Ratings/Ratings";
import DownloadExperiment from "./pages/DownloadExperiment";




const router = createBrowserRouter([
  {
    path: "/login",
    // element: <NotFound />,
    element: <Login />,
  },
  {
    path: "/welcome",
    element: <Welcome />,
  },
  {
    path: "/order",
    element: <ProtectedComponent Component={OrderScreen}/>
  },
  {
    path: "/restaurant/:id",
    element: <SplashScreen />,
  },
  {
    path: "/cart",
    element: <ProtectedComponent Component={Cart}/>
  },
  {
    path: "/history",
    element: <ProtectedComponent Component={History}/>
  },
  {
    path: "/waitlist/:id/:code",
    element: <SplashScreen />,
  },
  {
    path: "/join-waitlist",
    element: <Waitlist />,
  },
  {
    path: "/waitlist-details",
    element: <WaitlistDetails />,
  },
  {
    path: "/rating/restaurant/:restaurantID/order/:orderID",
    element: <Ratings />,
  },
  {
    path: "/download",
    element: <DownloadExperiment />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);


// requestNotficationPermission()





axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["x-auth-token"] =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzZhMmM2NTk0NjQwYzFjMmU1NmIxNjgiLCJyb2xlIjoiVVNFUiIsImlhdCI6MTY4MTM3ODA0Mn0.fjQFGloMEsHyXB2LWJO_Wzm3brSPV4feuAszesfjXYI";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <RestaurantProvider>
        <UserProvider>
          <CartProvider>
            <WaitlistProvider>
              <SocketWrapper>
                <RouterProvider router={router} />
              </SocketWrapper>
            </WaitlistProvider>
          </CartProvider>
        </UserProvider>
      </RestaurantProvider>
    <ToastContainer
      style={{
        height: "50px",
      }}
      toastStyle={{
        minHeight: "50px",
        height: "50px",
      }}
      // bodyStyle={{
      // 	height: "40px",
      // }}
    />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
