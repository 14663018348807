import { toast as reactToast } from "react-toastify";

const defaultToastOptions = {
	position: "bottom-left",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: true,
	progress: undefined,
	theme: "colored",
};

const toast = {
	success: (message, options = {}) => {
		reactToast.success(message, { ...defaultToastOptions, ...options });
	},
	error: (message, options = {}) => {
		reactToast.error(message, { ...defaultToastOptions, ...options });
	},
	warning: (message, options = {}) => {
		reactToast.warning(message, { ...defaultToastOptions, ...options });
	},
	info: (message, options = {}) => {
		reactToast.info(message, { ...defaultToastOptions, ...options });
	},
	default: (message, options = {}) => {
		reactToast(message, { ...defaultToastOptions, ...options });
	},
};

export default toast;
