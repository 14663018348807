import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import logger from "../../utils/logger";
import { Typography } from "@mui/material";
import styles from "./History.module.css";
import KOTItemCard from "./KOTItemCard";
import { Alarm, ArrowBackRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { restaurant as restaurantContext } from '../../providers/RestaurantProvider'
import { toast } from "react-toastify";

const History = () => {
	const [order, setOrder] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const { restaurant } = useContext(restaurantContext)
	const totalTax = (restaurant?.taxes ?? 0).reduce((acc, tax) => acc + tax.value, 0);
	const subTotal = (order?.kots ?? []).reduce((acc, kot) => {
		return acc + kot.total;
	}, 0)

	const navigate = useNavigate();

	const fetchOrder = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${sessionStorage.getItem("restaurant")}/orders/table/${JSON.parse(sessionStorage.getItem("table"))._id
				}`
			);
			console.log(res.data, "orders")

			setOrder(res.data);
			setIsLoading(false);
		} catch (error) {
			logger(error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchOrder();
	}, []);
	const handleRazorPay = (order) => {
		const options = {
			key: "rzp_test_3LXlQwLTdi2Ojz",
			amount: order.amount,
			currency: order.currency,
			name: "Acme Corp",
			description: "Test Transaction",
			order_id: order.id,
			handler: async (response) => {
				toast.success("Payment Success");
			}
		}
		var rzp1 = new window.Razorpay(options);
		rzp1.open();
		rzp1.on('payment.failed', (response) => {
			toast.error("payment failed")
		})

	}
	const handleOrder = async () => {
		try {
			const { data } = await axios.post("/payments/razorpay/dinein/order", {
				restaurant: sessionStorage.getItem("restaurant"),
				table: JSON.parse(sessionStorage.getItem("table"))
			});
			handleRazorPay(data);
		} catch (err) {
			toast.error("Payment Failed")
		}
	}

	return isLoading ? (
		<></>
	) : !order ? (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				margin: "0 10px",
			}}
		>
			<ArrowBackRounded
				onClick={() => {
					navigate(-1);
				}}
				fontSize="large"
				htmlColor="#824500"
			/>
			<Typography
				variant="h4"
				component="div"
				color="#824500"
				fontWeight={400}
				sx={{
					paddingTop: "20px",
					paddingBottom: "20px",
					marginLeft: "16px",
				}}
			>
				No orders yet
			</Typography>
		</div>
	) : (
		<div className={styles.body}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					margin: "0 10px",
				}}
			>
				<ArrowBackRounded
					onClick={() => {
						navigate(-1);
					}}
					fontSize="medium"
					htmlColor="#824500"
				/>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontWeight={400}
					sx={{
						paddingTop: "20px",
						paddingBottom: "20px",
						marginLeft: "16px",
					}}
				>
					Table No : {order.table.name}
				</Typography>
			</div>
			{order.kots.map((kot, index) => {
				const now = Date.now();
				const kotTime = Date.parse(kot.createdAt);

				const diff = now - kotTime;

				const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

				const overdue = minutes >= 25;
				return (
					<div className={styles.kot}>
						<div className={styles.kot_heading}>
							<Typography
								variant="h6"
								component="span"
								color="#824500"
								fontWeight={400}
								fontSize={".99rem"}
								sx={{
									marginLeft: "8px",
								}}
							>
								KOT #{index + 1}
							</Typography>
							<Typography
								variant="body"
								component="span"
								color="#824500"
								fontWeight={400}
								sx={{
									marginLeft: "8px",
								}}
							>
								[{minutes} mins]
							</Typography>
							{overdue && (
								<Alarm
									htmlColor="#824500"
									sx={{
										marginLeft: "12px",
									}}
								/>
							)}
						</div>
						<div className={styles.kot_item_list} >
							{kot.items.map((item) => {
								return <KOTItemCard item={item} kot={kot} />;
							})}
						</div>
					</div>
				);
			})}
			<div
				className={styles.btn_container}
			>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					display={"flex"}
					flexDirection={"column"}
					width={"100%"}
					fontWeight={400}
					paddingLeft={"40px"}
					paddingBottom={"5px"}
					fontSize={".99rem"}
				>
					<Typography
						width={"90%"}
						display={"flex"}
						justifyContent={"space-between"}
					>
						<span>
							Sub Total
						</span>
						<b>
							{subTotal}
						</b>
					</Typography>
					<Typography
						width={"90%"}
						display={"flex"}
						justifyContent={"space-between"}
					>
						<span>
							Tax
						</span>
						<b>
							{(subTotal * totalTax / 100).toFixed(2)}
						</b>
					</Typography>
					<Typography
						width={"90%"}
						display={"flex"}
						justifyContent={"space-between"}
					>
						<span>
							Grand Total :
						</span>
						<b>
							{Math.round(subTotal * (1 + (totalTax / 100)))}
						</b>
					</Typography>
				</Typography>
				{/* <button
					className={styles.pay_btn}
					onClick={handleOrder}
				>
					Pay Now
				</button> */}
			</div>
		</div>
	);
};

export default History;
