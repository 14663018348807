import { CircularProgress, Dialog } from "@mui/material";
import React, { useState, useContext } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./UserDetailInput.module.css";
import commonStyles from "../../../styles.module.css";
import { useNavigate } from "react-router-dom";
import { cart } from "../../../providers/CartProvider";
import axios from "axios";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";

const UserDetailInput = ({ isOpen, setIsOpen }) => {
	const [pax, setPax] = useState(1);
	const [isConfirming, setIsConfirming] = useState(false);

	const cartContext = useContext(cart);

	const navigate = useNavigate();

	const handleConfirm = async () => {
		if (cartContext.items.length === 0) {
			toast.error("Cart is empty!", {
				autoClose: 2000,
			});
			return;
		}

		try {
			setIsConfirming(true);
			const data = cartContext.items.map((item) => {
				return {
					...item,
					item: item.item._id,
				};
			});

			const res = await axios.post(
				`/restaurants/${sessionStorage.getItem("restaurant")}/kots`,
				{
					table: JSON.parse(sessionStorage.getItem("table"))._id,
					items: data,
					user: JSON.parse(localStorage.getItem("user")),
					personCount: pax,
				}
			);

			logger(res.data);
			cartContext.clearCart();
			setIsOpen(false);

			navigate("/order", { replace: true });
		} catch (error) {
			logger(error);
			toast.error(error?.response?.data?.message ?? "Error confirming order");
		} finally {
			setIsConfirming(false);
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setIsOpen(false);
			}}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<div className={styles.inputs}>

						<div className={styles.input}>
							<label htmlFor="pax">No. of People</label>
							<input
								type="number"
								id="pax"
								name="pax"
								onChange={(e) => {
									setPax(e.target.value);
								}}
								value={pax}
								style={{
									width: "40px",
									textAlign: "center",
								}}
							/>
						</div>
					</div>
					<div className={styles.submit}>
						<button
							className={commonStyles.primary_button}
							onClick={handleConfirm}
						>
							{isConfirming ? (
								<CircularProgress
									color="inherit"
									size={18}
									sx={{
										padding: "0px 12px",
									}}
								/>
							) : (
								"Submit"
							)}
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default UserDetailInput;
