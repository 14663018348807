import React from "react";
import styles from "./GradientSwitch.module.css";

const GradientSwitch = ({ gradient, checked, onChange }) => {
	return (
		<div
			className={styles.switch_base}
			style={{
				background: gradient,
				opacity: checked ? 1 : 0.5,
			}}
			onClick={() => {
				onChange(!checked);
			}}
		>
			<div
				className={styles.switch_thumb}
				style={{
					transform: checked ? "translateX(18px)" : "translateX(0px)",
				}}
			></div>
		</div>
	);
};

export default GradientSwitch;
