import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

console.log("inside firebase");

function requestNotificationPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // Permission granted, proceed to get the FCM token
      console.log("Notification permission granted.");
    } else {
      console.log("Notification permission denied.");
    }
  });
  getFCMToken()
}

onMessage(messaging, (payload) => {
  console.log("message", payload) 
  var notification = new Notification(payload.notification.title, {
    body: payload.notification.body
  });
  notification.addEventListener('click', () => {
    window.location.href = payload.data.redirectLink
  });

});

export const getFCMToken=()=>{
  return new Promise((resolve, reject)=>{
    getToken(messaging, {
      vapidKey:
        "BDssKwAOUIXELI8dLbTEg9EulYQoBtwXLhpnqHpW8iEVOF1R5UfgoRTYFYT63D0nqfprLy-8gYWfORuACMuQpZo",
    }).then((currentToken) => {
        if (currentToken) {
          console.log("current token", currentToken);
          resolve(currentToken)
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          requestNotificationPermission()
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token.", err);
        reject(err)
        // ...
      });
  }) 
}
export { messaging };
