import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import styles from "./WaitlistDetails.module.css";
import { restaurant } from "../../providers/RestaurantProvider";
import shakespeareLogoText from "../../assets/images/shakespeare_logo_text.png";
import commonStyles from "../../styles.module.css";
import { Typography } from "@mui/material";
import { waitlistContext } from "../../providers/WaitListProvider";

const WaitlistDetails = () => {
	const [waitlist, setWaitlist] = useState([]);
	const { waitlist: waitlistData } = useContext(waitlistContext)
	const navigate = useNavigate();
	const restaurantContext = useContext(restaurant);

	const fetchWaitlist = async () => {
		try {
			const today = new Date();
			const todayTimestamp = today.setHours(0, 0, 0, 0);
			const res = await axios.get(
				`/restaurants/${sessionStorage.getItem(
					"restaurant"
				)}/waitlist/timeRange?from=${todayTimestamp}&to=${todayTimestamp + 86400000
				}`
			);
			console.log(res.data)

			logger(res.data);
			setWaitlist(res.data);
		} catch (err) {
			logger(err);
		}
	};

	const handleCancel = async () => {
		try {
			const res = await axios.put(
				`/restaurants/${sessionStorage.getItem(
					"restaurant"
				)}/waitlist/${localStorage.getItem("waitlistID")}`,
				{
					status: "cancelled",
				}
			);

			logger(res.data);
			localStorage.removeItem("waitlistID");

			toast.success("Successfully cancelled your waitlist");
			// navigate("/join-waitlist", { replace: true });
		} catch (err) {
			logger(err);
			toast.error("Failed to cancel your waitlist");
		}
	};

	useEffect(() => {
		fetchWaitlist();
	}, []);

	return (
		<div className={styles.body}>
			<div className={styles.images}>
				<img src={shakespeareLogoText} alt="Shakespeare Logo" />
				{restaurantContext.restaurant.image && (
					<img src={restaurantContext.restaurant.image} alt="Restaurant Logo" />
				)}
			</div>
			<div className={styles.form}>
				{
					waitlistData?.status === "waiting" ?
						<>
							{waitlist.length === 0
								? <Typography
								variant="h4"
								style={{
									fontWeight: "bold",
									marginBottom: "20px",
								}}
								color="#9E5E28"
							>
								No waitlist
							</Typography> :
								<>
							<Typography
								variant="h6"
								style={{
									fontWeight: "bold",
									marginBottom: "10px",
								}}
								color="#9E5E28"
							>
								Your Waitlist Number is:{" "}
							</Typography>
							<Typography
								variant="h4"
								style={{
									fontWeight: "bold",
									marginBottom: "20px",
								}}
								color="#9E5E28"
							>
								
									{waitlist.findIndex(
										(w) => w._id === localStorage.getItem("waitlistID")
									) + 1}
							</Typography>
							</>
							}
							{waitlist.length !== 0 &&
								<button
									className={commonStyles.secondary_button}
									onClick={handleCancel}
									style={{
										fontSize: "16px",
									}}
								>
									Cancel Waitlist
								</button>}
						</>
						: (waitlistData?.status === "seated" || waitlistData?.status === "cancelled") ?
							<>
								<Typography
									variant="h6"
									style={{
										fontWeight: "bold",
										marginBottom: "10px",
									}}
									color="#9E5E28" >
									Your Waitlist is {waitlistData?.status === 'seated' ? "Approved" : "Cancelled"}
								</Typography>
							</>
							:
							<>
								<Typography
									variant="h6"
									style={{
										fontWeight: "bold",
										marginBottom: "10px",
									}}
									color="#9E5E28"
								>
									No waitlist
								</Typography>
							</>
				}

			</div>
			<footer>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
		</div>
	);
};

export default WaitlistDetails;
