import {
	ArrowBackRounded,
	RemoveShoppingCartRounded,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import { cart } from "../../providers/CartProvider";
import { restaurant } from "../../providers/RestaurantProvider";
import toast from "../../utils/toast";
import styles from "./Cart.module.css";
import commonStyles from '../../styles.module.css'
import OTPDialog from "./OTPDialog";
import logger from "../../utils/logger";
import UserDetailInput from "./UserDetailInput/UserDetailInput";

const Cart = () => {
	const cartContext = useContext(cart);
	const restaurantContext = useContext(restaurant);
	const [isUserDetailInputOpen, setIsUserDetailInput] = useState(false)
	const navigate = useNavigate();

	const [otpHash, setOtpHash] = useState("");

	const [otpDialogOpen, setOtpDialogOpen] = useState(false);
	const [isConfirming, setIsConfirming] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const handleConfirm = async () => {
		try {
			const res = await axios.post(
				`/restaurants/${sessionStorage.getItem("restaurant")}/kots/sendOTP`,
				{
					phone: JSON.parse(localStorage.getItem("user")).phone,
				}
			);

			setOtpHash(res.data.otpHash);
			setOtpDialogOpen(true);
		} catch (error) {
			logger(error);
			toast.error("Error sending OTP");
		}
	};

	const handleOrder = async () => {
		if (cartContext.items.length === 0) {
			toast.error("Cart is empty", {
				autoClose: 2000,
			});
			return;
		}

		try {
			setIsConfirming(true);
			const {data:order} = await axios.get(
				`/restaurants/${sessionStorage.getItem("restaurant")}/orders/table/${JSON.parse(sessionStorage.getItem("table"))._id}`,
			);
			if(!order){
					setIsUserDetailInput(true);
				return ;
			}
			if(order.status==="completed"){
				toast.error("Order on this table is already completed");
				return;
			}
			
			const data = cartContext.items.map((item) => {
				return {
					...item,
					item: item.item._id,
				};
			});

			const res = await axios.post(
				`/restaurants/${sessionStorage.getItem("restaurant")}/kots`,
				{
					table: JSON.parse(sessionStorage.getItem("table"))._id,
					items: data,
					user: JSON.parse(localStorage.getItem("user")),
				}
			);

			logger(res.data);

			toast.success("Order confirmed");
			navigate("/history")
			cartContext.clearCart();

			// navigate("/order", { replace: true });
		} catch (error) {
			logger(error);
			toast.error(error?.response?.data?.message ?? "Error confirming order");
		} finally {
			setIsConfirming(false);
		}
	};

	const OrderItemCard = ({ item }) => {
		const quantity = cartContext.getQuantity(item.item._id, item.properties);
		logger(item);

		return (
			<>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.item_card_wrapper}
				>
					<div>
						<div className={styles.item}>
							<div className={styles.item_details}>
								<Typography
									variant="body2"
									component="div"
									color="#824500"
									fontWeight={600}
								>
									{item.item.name}
								</Typography>
								<ul className={styles.property_list}>
									{(item.properties || []).map((prop) => {
										return (
											<Typography
												variant="caption"
												component="div"
												color="#824500"
												fontWeight={400}
											>
												<li key={prop.name}>
													{prop.name} : {prop?.option ? prop?.option : prop?.options?.join(", ") ?? ""}
												</li>
											</Typography>
										);
									})}
								</ul>
							</div>
							<div className={styles.counter}>
								<div
									className={styles.counter_button}
									onClick={
										quantity === 0
											? undefined
											: () => {
												cartContext.subtractItem(item.item, item.properties);
											}
									}
								>
									-
								</div>
								<div className={styles.counter_value}>{quantity}</div>
								<div
									className={styles.counter_button}
									onClick={() => {
										cartContext.addItem(item.item, item.properties);
									}}
								>
									+
								</div>
							</div>
						</div>
						{item.note && (
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={400}
								textAlign="left"
							>
								<strong>Note: </strong> {item.note}
							</Typography>
						)}
					</div>
				</GradientBorder>
				<OTPDialog
					open={otpDialogOpen}
					handleClose={() => setOtpDialogOpen(false)}
					otpHash={otpHash}
					handleSuccess={handleOrder}
				/>
			</>
		);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				backgroundColor: "#FEFAF3",
			}}
		>
			<div
				style={{
					flexGrow: 1,
				}}
			>
				<div>
					<div class={styles.header_row}>
						<IconButton
							onClick={() => {
								navigate("/order", { replace: true });
							}}
						>
							<ArrowBackRounded fontSize="medium" htmlColor="#814500" />
						</IconButton>
						<Typography
							variant="h6"
							component="div"
							color="#824500"
							fontWeight={400}
							sx={{
								marginTop: "10px",
								marginBottom: "10px",
							}}
						>
							Table No : {restaurantContext.table.name}
						</Typography>
						<IconButton
							onClick={() => {
								cartContext.clearCart();
							}}
						>
							<RemoveShoppingCartRounded fontSize="medium" htmlColor="#814500" />
						</IconButton>
					</div>
					<div className={styles.item_list}>
						{cartContext.items.map((item) => {
							return <OrderItemCard key={item._id} item={item} />;
						})}
					</div>
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={400}
						fontSize={".99rem"}
						sx={{
							marginTop: "20px",
							marginLeft: "30px",
						}}
					>
						Total : {cartContext.getCartTotal()}
					</Typography>
				</div>
				<Typography
						variant="div"
						component="div"
						display={"flex"}
						justifyContent={"center"}
					>
				<button
					width="1px"
					className={commonStyles.secondary_button}
				>
					<div onClick={isConfirming ? null : handleOrder}>
						{isConfirming ? (
							<CircularProgress
								color="inherit"
								size={24}
								sx={{
									padding: "0px 48px",
								}}
							/>
						) : (
							"Confirm Order"
						)}
					</div>
				</button>
				</Typography>
			</div>
			<UserDetailInput
				isOpen={isUserDetailInputOpen}
				setIsOpen={setIsUserDetailInput}
				table={JSON.parse(sessionStorage.getItem("table"))}
			/>
			<footer className={styles.footer}>
				<a href="https://shakespeare.vision">www.shakespeare.vision</a>
			</footer>
		</div>
	);
};

export default Cart;
