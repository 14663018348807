import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import { restaurant } from "../../providers/RestaurantProvider";
import { cart } from "../../providers/CartProvider";
import styles from "./OrderScreen.module.css";
import ironmanThali from "../../assets/gifs/iron_man_thali.gif";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import { Breadcrumbs, Typography } from "@mui/material";
import ItemCard from "./ItemCard";
import SubCategoryCard from "./SubCategoryCard";
import PropertyOptionCard from "./PropertyOptionCard";
import toast from "../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import MenuFAB from "./MenuFAB";
import GradientSwitch from "../../components/GradientSwitch/GradientSwitch";
import waiterIcon from "../../assets/icons/waiter.png";
import commonStyles from "../../styles.module.css";
import { MenuBookRounded, Refresh, ThumbUp, ThumbsUpDown, ArrowRight, ArrowLeft } from "@mui/icons-material";
import logger from "../../utils/logger";
import axios from "axios";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoFixOff from '@mui/icons-material/AutoFixOff';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const OrderScreen = (props) => {
	const navigate = useNavigate();

	const [recommendations, setRecommendations] = useState([])
	const [skip, setSkip] = useState(0)

	const restaurantContext = useContext(restaurant);
	const cartContext = useContext(cart);
	// let veg  = ""
	const location = useLocation();
	// localStorage.setItem("showNonVeg", true)

	const [showVeg, setShowVeg] = useState(
		JSON.parse(localStorage.getItem("showVeg"))
	);
	const [showNonVeg, setShowNonVeg] = useState(
		JSON.parse(localStorage.getItem("showNonVeg"))
	);

	const {
		historyLength,
		currentMenu,
		selectedItem,
		showProperties,
		propertyIndex,
		properties,
		selectedProperties,
		selectedMultipleProperties,
		showNote,
		breadCrumbs,
	} = location.state || {
		historyLength: 0,
		currentMenu: restaurantContext.restaurant.menu || {},
		selectedItem: null,
		showProperties: false,
		propertyIndex: 0,
		properties: [],
		selectedProperties: {},
		selectedMultipleProperties: {},
		showNote: false,
		breadCrumbs: [],
	};
	const [selections, setSelections] = useState({ ...selectedProperties });
	const [multipleSelections, setMultipleSelections] = useState({ ...selectedMultipleProperties });

	let note = location.state?.note || "";

	const [isSearching, setIsSearching] = useState(
		false
	);
	const [searchQuery, setSearchQuery] = useState("");


	useEffect(() => {
		if (window.scrollY > 0) window.scrollTo(0, 0);
		if (!sessionStorage.getItem('interactingUser')) {
			sessionStorage.setItem('interactingUser', Date.now().toString())
		}
	}, []);
	useEffect(() => {
		(async function () {
			try {
				const res = await axios.get(
					`/restaurants/${sessionStorage.getItem("restaurant")}/items/most-sold?limit=6&skip=${skip}`,
				);
				setRecommendations(res.data);
				if (res.data.length === 0) {
					setSkip(0)
				}
				console.log(res);
			} catch (err) {

			}
		})()

	}, [skip])
	const handleBack = () => {
		if (isSearching) {
			setIsSearching(!isSearching)
			setSearchQuery("")
		}
		else {
			navigate(-1);
		}


	};

	const [likes, setLikes] = useState({})
	const [dislikes, setDislikes] = useState({})

	const MenuView = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontWeight={600}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						textAlign: "center",
					}}
				>
					{currentMenu?.name || "Main Menu"}
				</Typography>
				<div className={styles.card_grid}>
					{(currentMenu?.subCategories || (currentMenu ?? [])).map(
						(subCategory, index) => {
							return (
								<SubCategoryCard
									key={index}
									category={subCategory}
									onClick={() => {
										navigate("/order", {
											state: {
												historyLength: historyLength + 1,
												currentMenu: subCategory,
												selectedItem: null,
												showProperties: false,
												propertyIndex: 0,
												properties: [],
												selectedProperties: {},
												selectedMultipleProperties: {},
												showNote: false,
												breadCrumbs: [...breadCrumbs, subCategory.name],
												note: "",
											},
										});
									}}
								/>
							);
						}
					)}
				</div>
				<div className={styles.card_grid}>
					{(currentMenu?.items || []).map((item) => {
						return (
							<span
								key={item._id}
								style={{
									display:
										restaurantContext.items[item].isBar ||
											restaurantContext.items[item].isVeg === null ||
											(restaurantContext.items[item].isVeg && showVeg) ||
											(!restaurantContext.items[item].isVeg && showNonVeg)
											? "block"
											: "none",
								}}
							>
								<ItemCard
									item={restaurantContext.items[item]}
									onProperties={() => {
										navigate("/order", {
											state: {
												historyLength: historyLength + 1,
												currentMenu: currentMenu,
												selectedItem: restaurantContext.items[item],
												showProperties: true,
												propertyIndex: 0,
												properties: restaurantContext.items[item].properties,
												selectedProperties: {},
												selectedMultipleProperties: {},
												showNote: false,
												breadCrumbs: [
													...breadCrumbs,
													restaurantContext.items[item].name,
												],
												note: "",
											},
										});
									}}
								/>
							</span>
						);
					})}
				</div>
			</div>
		);
	};

	const PropertiesView = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontSize={".97rem"}
					fontWeight={600}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						textAlign: "center",
					}}
				>
					{properties[propertyIndex].name}
					{selectedItem.maxPropertySelectionsAllowed && ` (You can only select upto ${selectedItem.maxPropertySelectionsAllowed} properties)`}
					<br />
					<span style={{ fontSize: ".87rem" }}>

						{properties[propertyIndex].hasMultipleSelection ? ` Multiple Selection Available ` : null}
						{properties[propertyIndex].maxSelectionsAllowed ? ` | Max (${properties[propertyIndex].maxSelectionsAllowed})` : null}
						{(properties[propertyIndex].minSelectionsRequired && properties[propertyIndex].isMandatory) ? ` | Min (${properties[propertyIndex].minSelectionsRequired})` : null}
					</span>
				</Typography>
				<div className={styles.card_grid}>
					{properties[propertyIndex].options.map((option, index) => {
						console.log("properties[propertyIndex]", properties)
						return (
							<PropertyOptionCard
								key={index}
								isBar={properties[propertyIndex]?.isBar}
								option={option}
								selected={
									(() => {
										if (properties[propertyIndex].hasMultipleSelection) {
											console.log("has multiple", multipleSelections[properties[propertyIndex].name]?.includes(option.name))
											return multipleSelections[properties[propertyIndex].name]?.includes(option.name)
										} else {
											return selections[properties[propertyIndex].name] === option.name
										}
									})()
								}
								onClick={() => {
									if (properties[propertyIndex].hasMultipleSelection) {
										if (multipleSelections?.[properties[propertyIndex].name]?.includes(option.name)) {
											let updatedMultipleSelections = { ...multipleSelections }
											updatedMultipleSelections[properties[propertyIndex].name] = multipleSelections[properties[propertyIndex].name].filter((optionName) => {
												return optionName !== option.name
											})
											if (JSON.stringify(updatedMultipleSelections[properties[propertyIndex].name]) == "[]") {
												delete updatedMultipleSelections[properties[propertyIndex].name];
											}
											setMultipleSelections({
												...updatedMultipleSelections
											});
										} else {
											if (properties[propertyIndex].maxSelectionsAllowed) {
												if ((multipleSelections[properties[propertyIndex].name] ?? []).length >= properties[propertyIndex].maxSelectionsAllowed) {
													toast.error(`You can't select more than ${properties[propertyIndex].maxSelectionsAllowed} options`)
													return;
												}
											}
											setMultipleSelections({
												...multipleSelections,
												[properties[propertyIndex].name]: [...(multipleSelections?.[properties[propertyIndex].name] ?? []), option.name],
											});

										}
									} else {
										// setSelections({
										// 	...selections,
										// 	[properties[propertyIndex].name]: option.name
										// });
										if (selections?.[properties[propertyIndex].name]?.includes(option.name)) {
											const updatedSelections = { ...selections }
											delete updatedSelections[properties[propertyIndex].name];
											setSelections({
												...updatedSelections
											});
										} else {
											setSelections({
												...selections,
												[properties[propertyIndex].name]: option.name,
											});
										}

									}

								}}
							/>
						);
					})}
				</div>
				<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<div
						className={[commonStyles.primary_button, styles.confirm].join(" ")}
						style={{ animation: "none", width: "150px" }}
						onClick={() => {
							if (properties[propertyIndex].isMandatory) {
								if (properties[propertyIndex].hasMultipleSelection && (multipleSelections[properties[propertyIndex].name] ?? []).length === 0) {
									toast.error(`Property ${properties[propertyIndex].name} is mandatory`, {
										autoClose: 2000,
									});
									return;
								} else if (!properties[propertyIndex].hasMultipleSelection && !selections[properties[propertyIndex].name]) {
									toast.error(`Property ${properties[propertyIndex].name} is mandatory`, {
										autoClose: 2000,
									});
									return;
								}
								if (properties[propertyIndex].minSelectionsRequired && properties[propertyIndex].hasMultipleSelection) {
									if ((multipleSelections[properties[propertyIndex].name] ?? []).length < properties[propertyIndex].minSelectionsRequired) {
										toast.error(`You need to select atleast ${properties[propertyIndex].minSelectionsRequired} options in ${properties[propertyIndex].name} property`, {
											autoClose: 2000,
										});
										return;
									}
								}
							}
							if (properties[propertyIndex].maxSelectionsAllowed && properties[propertyIndex].hasMultipleSelection) {
								if ((multipleSelections[properties[propertyIndex].name] ?? []).length > properties[propertyIndex].maxSelectionsAllowed) {
									toast.error(`You can only select upto ${properties[propertyIndex].maxSelectionsAllowed} options in ${properties[propertyIndex].name} property`, {
										autoClose: 2000,
									});
									return;
								}
							}
							navigate("/order", {
								state: {
									historyLength: historyLength + 1,
									currentMenu: currentMenu,
									selectedItem: selectedItem,
									showProperties: !(
										propertyIndex ===
										properties.length - 1
									),
									properties: properties,
									propertyIndex:
										propertyIndex +
										(propertyIndex === properties.length - 1 ? 0 : 1),
									selectedProperties: selections,
									selectedMultipleProperties: multipleSelections,
									showNote: propertyIndex === properties.length - 1,
									breadCrumbs: [...breadCrumbs, (selections[properties[propertyIndex].name] ?? (multipleSelections?.[properties[propertyIndex]?.name] ?? []).join(", "))],
									note: "",
								},
							});
						}}
					>
						Next
					</div>
				</div>

			</div>
		);
	};

	const NoteView = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontWeight={600}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						textAlign: "center",
					}}
				>
					Add Note
				</Typography>

				<div className={styles.note_card}>
					<textarea
						className={styles.note_textarea}
						placeholder="Add a note"
						onChange={(e) => {
							note = e.target.value;
						}}
					/>
				</div>

				<GradientBorder
					gradient="linear-gradient(106.33deg, #918159 12.16%, #E5D6B2 104.96%)"
					className={styles.note_button}
					width="1px"
				>
					<button
						onClick={() => {
							const allProperties = []
							Object.keys(selectedProperties).forEach((key) => {
								allProperties.push({
									name: key,
									option: selectedProperties[key],
								})
							})
							Object.keys(selectedMultipleProperties).forEach((key) => {
								allProperties.push({
									name: key,
									options: selectedMultipleProperties[key]
								})
							});
							if (selectedItem.maxPropertySelectionsAllowed) {
								if (allProperties.length > selectedItem.maxPropertySelectionsAllowed) {
									return toast.error(`You can only select upto ${selectedItem.maxPropertySelectionsAllowed} properties`)
								}
							}
							cartContext.addItem(
								selectedItem,
								allProperties,
								note
							);
							navigate(-historyLength);
						}}
					>
						Confirm
					</button>
				</GradientBorder>
			</div>
		);
	};

	const SearchView = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontWeight={600}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						textAlign: "center",
					}}
				>
					Search Results
				</Typography>
				<div className={styles.card_grid}>
					{Object.values(restaurantContext.items || {})
						.filter((item) => {
							return item.name
								.toLowerCase()
								.includes(searchQuery.toLowerCase());
						})
						.map((item) => {
							console.log("fditem", item)
							return (
								<span
									key={item._id}
									style={{
										display:
											item.isBar ||
												(item.isVeg && showVeg) ||
												(!item.isVeg && showNonVeg)
												? "block"
												: "none",
									}}
								>
									<ItemCard
										key={item._id}
										item={item}
										onProperties={() => {
											setIsSearching(!isSearching)
											setSearchQuery("")
											navigate("/order", {
												state: {
													historyLength: historyLength + 1,
													currentMenu: currentMenu,
													selectedItem: item,
													showProperties: true,
													propertyIndex: 0,
													properties: item.properties,
													selectedProperties: {},
													selectedMultipleProperties: {},
													showNote: false,
													breadCrumbs: [...breadCrumbs, item.name],
													note: "",
												},
											});
										}}
									/>
								</span>
							);
						})}
				</div>
			</div>
		);
	};

	const RecommendationView = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					fontWeight={600}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						textAlign: "center",
					}}
				>
					{JSON.parse(localStorage.getItem('user')).name}'s Recommendations
				</Typography>
				<div className={styles.card_grid}>
					{recommendations.map((it) => {
						const item = it.itemDetails
						return (
							<span
								key={item._id}
								style={{
									display:
										item.isBar ||
											item.isVeg === null ||
											(item.isVeg && showVeg) ||
											(!item.isVeg && showNonVeg)
											? "block"
											: "none",
								}}
							>
								<ItemCard
									theme="brown"
									key={item._id}
									item={item}
									onProperties={() => {
										setIsSearching(!isSearching)
										setSearchQuery("")
										navigate("/order", {
											state: {
												historyLength: historyLength + 1,
												currentMenu: currentMenu,
												selectedItem: item,
												showProperties: true,
												propertyIndex: 0,
												properties: item.properties,
												selectedProperties: {},
												selectedMultipleProperties: {},
												showNote: false,
												breadCrumbs: [...breadCrumbs, item.name],
												note: "",
											},
										});
									}}
								/>
								{/* <div style={{ width: "60%", margin: "35px", display: "flex", justifyContent: "space-evenly", backgroundColor: "white", border: "1px solid brown", borderRadius: "0px 0px 20px 20px", marginTop: "-9.5px", zIndex: "999" }}>
									<span onClick={() => {
										if (likes[item._id]) {
											setLikes({
												...likes,
												[item._id]: false
											});
											handleLike("like off", item)

										} else {
											setLikes({
												...likes,
												[item._id]: true
											})
											handleLike('like on', item)
											if (dislikes[item._id]) {
												setDislikes({
													...dislikes,
													[item._id]: false
												})
												handleLike('dislike off', item)
											}
										}
									}}>
										{
											likes[item._id] ?
												<ThumbUp sx={{ color: "brown", cursor: "pointer", fontSize: ".97rem" }} /> :
												<ThumbUpOffAltIcon sx={{ color: "brown", cursor: "pointer", fontSize: ".97rem" }} />
										}
									</span>
									<span onClick={() => {
										if (dislikes[item._id]) {
											setDislikes({
												...dislikes,
												[item._id]: false
											})
											handleLike('dislike off', item)
										} else {
											setDislikes({
												...dislikes,
												[item._id]: true
											})
											handleLike('dislike on', item)
											if (likes[item._id]) {
												setLikes({
													...likes,
													[item._id]: false
												})
											}
											handleLike('like off', item)
										}
									}}>
										{
											dislikes[item._id] ?
												<ThumbDownAltIcon sx={{ color: "brown", cursor: "pointer", fontSize: ".97rem" }} /> :
												<ThumbDownOffAltIcon sx={{ color: "brown", cursor: "pointer", fontSize: ".97rem" }} />
										}
									</span>
								</div> */}
							</span>
						);
					})}
				</div>
				<Typography
					variant="div"
					component="div"
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<button onClick={() => {
						setSkip((skip - 3)<=0 ? 0 : (skip - 3));
						const data = {
							event: "recommendation refresh",
							table: restaurantContext.table._id
						}
						let experimentData = JSON.parse(localStorage.getItem('experimentData'));
						if (!experimentData) {
							experimentData = []
						}
						experimentData.push(data)
						localStorage.setItem('experimentData', JSON.stringify(experimentData));
					}} style={{ width: "100px", height: "30px" }} className={commonStyles.secondary_button}>
						<ArrowLeft fontSize=".95rem"></ArrowLeft>
						&nbsp;
						Back
					</button>
					<button onClick={() => {
						setSkip(skip + 3);
						const data = {
							event: "recommendation refresh",
							table: restaurantContext.table._id
						}
						let experimentData = JSON.parse(localStorage.getItem('experimentData'));
						if (!experimentData) {
							experimentData = []
						}
						experimentData.push(data)
						localStorage.setItem('experimentData', JSON.stringify(experimentData));
					}} style={{ width: "100px", height: "30px" }} className={commonStyles.secondary_button}>
						Next &nbsp;
						<ArrowRight fontSize=".95rem"></ArrowRight>
					</button>
				</Typography>
			</div>
		);
	};

	const SummaryBar = () => {
		const itemCount = cartContext.getItemCount();

		return (
			<div
				className={styles.summary_bar}
				style={{
					maxHeight: itemCount > 0 ? "200px" : "0px",
					opacity: itemCount > 0 ? 1 : 0,
					transition: "0.3s",
				}}
			>
				<div className={styles.total_summary}>
					{itemCount} {"Item" + (itemCount > 1 ? "s" : "")} | ₹
					{cartContext.getCartTotal()}
				</div>
				<div
					className={styles.button}
					onClick={() => {
						navigate("/cart");
					}}
				>
					View Cart
				</div>
			</div>
		);
	};

	const [showRecommendation, setShowRecommendation] = useState(false)
	const [first, setFirst] = useState(true)

	useEffect(() => {
		if (!first) {
			const data = {
				event: "recommendation " + (showRecommendation ? "on" : "off"),
				table: restaurantContext.table._id,
				user: sessionStorage.getItem('interactingUser') ?? ""
			}
			let experimentData = JSON.parse(localStorage.getItem('experimentData'));
			if (!experimentData) {
				experimentData = []
			}
			experimentData.push(data)
			localStorage.setItem('experimentData', JSON.stringify(experimentData));
		}
		setFirst(false)
	}, [showRecommendation])
	useEffect(() => {
		if (!first) {
			const data = {
				event: "veg " + (showVeg ? "on" : "off"),
				table: restaurantContext.table._id,
				user: sessionStorage.getItem('interactingUser') ?? ""
			}
			let experimentData = JSON.parse(localStorage.getItem('experimentData'));
			if (!experimentData) {
				experimentData = []
			}
			experimentData.push(data)
			localStorage.setItem('experimentData', JSON.stringify(experimentData));
		}
		setFirst(false)
	}, [showVeg])

	useEffect(() => {
		if (!first) {
			const data = {
				event: "nonVeg " + (showNonVeg ? "on" : "off"),
				table: restaurantContext.table._id,
				user: sessionStorage.getItem('interactingUser') ?? ""
			}
			let experimentData = JSON.parse(localStorage.getItem('experimentData'));
			if (!experimentData) {
				experimentData = []
			}
			experimentData.push(data)
			localStorage.setItem('experimentData', JSON.stringify(experimentData));
		}
		setFirst(false)
	}, [showNonVeg])


	const handleLike = (event, item) => {
		const data = {
			event: event,
			itemId: item._id,
			itemName: item.name,
			table: restaurantContext.table._id,
			user: sessionStorage.getItem('interactingUser') ?? ""
		}
		let experimentData = JSON.parse(localStorage.getItem('experimentData'));
		if (!experimentData) {
			experimentData = []
		}
		experimentData.push(data)
		localStorage.setItem('experimentData', JSON.stringify(experimentData));
	}

	return (
		<div
			style={{
				minHeight: "100vh",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div
				className={styles.body}
				style={{
					flexGrow: 1,
				}}
			>
				<Header
					onBack={handleBack}
					onSearch={(query) => {
						setSearchQuery(query);
						setIsSearching(query !== "");
					}}
					query={searchQuery}
				/>
				{restaurantContext.restaurant.image && (
					<img src={restaurantContext.restaurant.image} alt="Restaurant Logo" />
				)}

				<div
					className={
						styles.hero_section + (isSearching ? " " + styles.hide : "")
					}
				>
					<div className={styles.hero_button}>
						<MenuBookRounded htmlColor="#8B4600" />
						<button
							className={commonStyles.primary_button}
							onClick={() => {
								if (historyLength > 0) navigate(-historyLength);
							}}
						>
							Main Menu
						</button>
					</div>
					<div className={styles.video}>
						<img src={ironmanThali} alt="Ironman Thali" />
					</div>
					<div className={styles.hero_button}>
						<img src={waiterIcon} alt="Waiter" />
						<button
							className={commonStyles.primary_button}
							onClick={async () => {
								try {
									const res = await axios.get(
										`/restaurants/${restaurantContext.restaurant._id}/orders/waiter/${restaurantContext.table._id}`
									);

									logger(res.data);

									toast.info("Waiter called!", {
										autoClose: 2000,
									});
								} catch (err) {
									logger(err);
								}
							}}
						>
							Call Waiter
						</button>
					</div>
				</div>
				<div className={styles.filter_row}>
					<div className={styles.filter}>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
							fontFamily={"Poppins"}
						>
							Veg
						</Typography>
						<GradientSwitch
							checked={showVeg}
							onChange={() => {
								localStorage.setItem("showVeg", !showVeg)
								setShowVeg(!showVeg);
							}}
							gradient="linear-gradient(to right, #00E08F 0%, #028B59 100%)"
						/>
					</div>

					<div className={styles.filter}>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
							fontFamily={"Poppins"}
						>
							Non-Veg
						</Typography>
						<GradientSwitch
							checked={showNonVeg}
							onChange={() => {
								localStorage.setItem("showNonVeg", !showNonVeg)
								setShowNonVeg(!showNonVeg);
							}}
							gradient="linear-gradient(to right, #FF578C 0%, #E50149 100%)"
						/>
					</div>
				</div>
				<div className={styles.filter_row} style={{ marginTop: "100px", display: "flex", justifyContent: "center" }}>
					<div className={styles.filter}>

						<div className={styles.hero_button}>
							<button
								className={commonStyles.primary_button}
								style={{ maxWidth: "170px", fontSize: '.75rem', padding: "10px" }}
								onClick={() => {
									setShowRecommendation(!showRecommendation)
								}}
							>
								{showRecommendation ? "Hide" : `Show ${JSON.parse(localStorage.getItem('user')).name}'s`}  Recommendations
							</button>
						</div>
					</div>
				</div>
				<div className={styles.menu_section}>
					{
						showRecommendation &&
						<RecommendationView />
					}
				</div>
				<div className={styles.menu_section}>
					{!isSearching && breadCrumbs && breadCrumbs.length > 0 && (
						<>
							<Typography
								variant="body1"
								component="div"
								color="#776B51"
								fontWeight={500}
								sx={{
									textAlign: "center",
								}}
							>
								Your Build
							</Typography>
							<div className={styles.breadcrumbs}>
								<Breadcrumbs separator="-">
									{breadCrumbs.map((crumb, index) => {
										return (
											<Typography
												key={index}
												variant="body2"
												component="div"
												color="#776B51"
												fontWeight={600}
											>
												{crumb}
											</Typography>
										);
									})}
								</Breadcrumbs>
							</div>
						</>
					)}
					{isSearching ? (
						<SearchView />
					) : showNote ? (
						<NoteView />
					) : showProperties ? (
						<PropertiesView />
					) : (
						<MenuView />
					)}
				</div>
			</div>
			<footer className={styles.footer}>
				<a href="https://shakespeare.vision">www.shakespeare.vision</a>
			</footer>
			<SummaryBar />
			<MenuFAB
				menu={restaurantContext.restaurant.menu}
				onCategoryClick={(category) => {
					navigate("/order", {
						state: {
							historyLength: historyLength + 1,
							showProperties: false,
							showNote: false,
							breadCrumbs: [category.name],
							selectedProperties: {},
							selectedMultipleProperties: {},
							note: "",
							selectedItem: null,
							propertyIndex: 0,
							properties: [],
							currentMenu: category,
						},
					});
				}}
			/>
		</div>
	);
};

export default OrderScreen;
