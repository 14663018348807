import { createContext, useState } from "react";

const user = createContext({});

const { Provider } = user;

const UserProvider = ({ children }) => {
	const [user, setStateUser] = useState(
		JSON.parse(localStorage.getItem("user")) || {}
	);

	const setUser = (user) => {
		localStorage.setItem("user", JSON.stringify(user));
		setStateUser(user);
	};

	return (
		<Provider
			value={{
				user,
				setUser,
			}}
		>
			{children}
		</Provider>
	);
};

export { UserProvider, user };
